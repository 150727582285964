<template>
   <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>业务中台</el-breadcrumb-item>
           <el-breadcrumb-item>创作中心</el-breadcrumb-item>
           <el-breadcrumb-item>素材库</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <!-- 主体内容 -->
      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>素材标题：</label>
                  <el-input class="width-220"  v-model="titleQ" placeholder="素材标题" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>素材类别：</label>
                  <el-select v-model="typeQ"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                        v-for="item in typeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>素材类别：</label>
                  <el-select v-model="typeQ"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                        v-for="item in typeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
            </el-row>
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>素材状态：</label>
                  <el-select v-model="statusQ"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>上传时间：</label>
                  <date-packer class="width-220" :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
               </el-row>
               <el-button type="primary" @click="handleQuery(false)"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
               <el-button class="bg-gradient" @click="handleQuery(true)" type="primary"><span v-text="$t('msg.search')">搜索</span></el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button  class="bg-gradient" type="primary"  @click="handleAddEdit('add')"><span>素材上传</span></el-button>
               </el-row>
            </div>
            <el-table
                  ref="mainTable"
                  :data="tableData"
                  style="width: 100%"
                  :stripe="true"
                  >
               <el-table-column label="序号" min-width="30">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="title" label="标题" min-width="50"> </el-table-column>
               <el-table-column label="类别" min-width="30">
                  <template slot-scope="scope">
                     {{ scope.row.type | filterType }}
                  </template>
               </el-table-column>
               <el-table-column label="状态" min-width="30">
                  <template slot-scope="scope">
                     {{ scope.row.status | filterStatus }}
                  </template>
               </el-table-column>
               <el-table-column prop="createTime" label="上传时间"></el-table-column>
               <el-table-column prop="" label="编辑人"></el-table-column>
               <el-table-column prop="actualAmount" label="操作" min-width="100">
                  <template slot-scope="scope">
                  <el-button type="text" @click="handleDetail(scope.row)" >
                     <el-link type="primary">详情</el-link>
                  </el-button>
                  <el-button type="text" @click="handleAddEdit('edit', scope.row)">
                     <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                  </el-button>
                  <el-button type="text" style="color: #F56C6C;" @click="handleDel(scope.row.id)">
                     <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                  </el-button>
                  <el-button type="text" @click="visible = true">
                     <el-link type="primary">发布</el-link>
                  </el-button>
                  </template>
               </el-table-column>
            </el-table>
               <!-- 推广渠道 -->
            <el-form label-width="105px" class="popup-box">
               <el-dialog title="请选择推广渠道" :visible.sync="visible" width="400px">
                  <el-row class="radio-box">
                     <el-radio-group v-model="channel" style="display:flex; flex-wrap:wrap">
                        <p style="min-width:33.3%" v-for="(item, index) in channelList" :key="index">
                           <el-radio  :label="item">
                              {{ item.label }}
                           </el-radio>
                        </p>
                     </el-radio-group>
                  </el-row>
                  <el-row class="btm-btn">
                     <el-button class="bg-gradient" type="primary" @click="handleJump">去发布</el-button>
                  </el-row>
               </el-dialog>
            </el-form>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </el-row>
      </el-row>
   </div>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { mapState } from 'vuex'
   import {writing} from "@/api/interface/business";
   export default {
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.getMaterialPage()
               }
            }
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      data() {
         return {
            tableData: [],   // 表格用户列表
            hotelId: '',
            total: 0,   // 用户列表总条目数
            page: 1,   // 当前页 默认第一页
            limit: 0,  // 每页显示数
            channel: {},
            channelList: [
               { label: '微信群发活动', value: 'PUBLICACCOUNT', index: 0 },
               { label: '小程序弹窗', value: 'WECHAT', index: 1 },
               { label: '信息流广告', value: 'INFORMATION_FLOW_ADVERTISING', index: 2 },
               { label: '邮件', value: 'EMAIL', index: 3 },
               { label: 'web弹窗', value: 'WEB', index: 4 },
               { label: '短信', value: 'MESSAGE', index: 5 },
               { label: '抖音', value: 'DOUYIN', index: 6 }
            ],
            visible: false,
            typeQ: '',
            typeList: [
               { label: '全部', value: '' },
               { label: '图片', value: 'IMAGE' },
               { label: '图文', value: 'NEWS' },
               { label: '视频', value: 'VIDEO' },
            ],
            statusQ: '',
            statusList: [
               { label: '全部', value: '' },
               { label: '生效', value: 'EFFECTIVE' },
               { label: '草稿', value: 'DRAFT' }
            ],
            titleQ: '',
            dateValue: []
         }
      },
      inject: ['reload'],
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.hotelId = this.hotelInfo.id
         this.getMaterialPage()
      },
     methods: {
        // 监听注册事件
        handleFunc(){

        },
         // 获取素材库列表
         getMaterialPage(){
            const url = writing.materialPage
            const param = {
               companyId: this.hotelInfo.id,
               limit: this.limit,
               page: this.page,
               type: this.typeQ,
               status: this.statusQ,
               title: this.titleQ,
               startDate: this.dateValue[0],
               endDate: this.dateValue[1]
            }
            this.$axios.get(url, param).then(res => {
               if (res.success) {
                  this.total = res.total
                  this.tableData = res.records
               }
            })
         },
         // 改变当前日期
         getDatePacker(val){
            this.dateValue = val
         },
         // 查询素材
         handleQuery(bool) {
            if (bool) return this.getMaterialPage()
            this.typeQ = ''
            this.statusQ = ''
            this.titleQ = ''
            this.dateValue = []
         },
         // 删除素材
         handleDel(id){
            this.$confirm('您确定要删除当前素材吗？', '提示', {
               confirmButtonText: '确定',
               cancelButtonText: '取消',
               type: 'warning'
            }).then(() => {
               const url = writing.delMaterial
               const param = { id:id,hotelId:this.hotelInfo.id }
               this.$axios.post(url, param).then(() => {
                  this.$message({
                     showClose: true,
                     message: '删除成功！',
                     type: 'success'
                  })
                  this.getMaterialPage()
               })
            })
         },
         // 新增/编辑
         handleAddEdit(action, row){
            if (action === 'edit') sessionStorage.setItem('materialInfo', JSON.stringify(row))
            this.$router.push({path: '/material_list/putContent', query: { action }})
         },
         // 改变每页数
         pageChange(num) {
            this.limit = num
            this.getMaterialPage()
         },
         // 改变当前页
         handlePaging(num) {
            this.page = num
            this.getMaterialPage()
         },
         // 查看详情
         handleDetail(row) {
            sessionStorage.setItem('materialObj', JSON.stringify(row))
            this.$router.push('/material_list/detail')
         },
         // 跳转渠道
         handleJump(){
            sessionStorage.setItem('menuIndex', '2')
            sessionStorage.setItem('sideIndex', `1-${this.channel.index}`)
            this.$router.push({path: `/create_${this.channel.value.toLowerCase()}`, query: { act: 'add' }})
            this.reload()
         }
      },
      filters: {
         filterType(val){
            switch (val){
               case 'IMAGE':
                  return val = '图片'
               case 'NEWS':
                  return val = '图文'
               case 'VIDEO':
                  return val = '视频'
            }
         },
         filterStatus(val){
            switch (val){
               case 'EFFECTIVE':
                  return val = '生效'
               case 'DRAFT':
                  return val = '草稿'
            }
         },
      }
   }
</script>

<style lang="scss" scoped>
   .popup-box{
      .radio-box{
         // margin: 40px 0 40px 0;
         /deep/ .el-radio{ margin-bottom: 30px }
      }
      .btm-btn{ text-align: center }
   }
   /deep/ .el-dialog__header{
      padding:15px 20px 10px 20px;
   }
   /deep/ .el-radio__label{
      padding-left:2px;
   }
</style>
